import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ModulePopupService {

  private updatedList: any;
  public closeOption: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public close: BehaviorSubject<boolean> = new BehaviorSubject(false)
  constructor() { }

  updateList(list) {
    this.updatedList = list;
  }

  getUpdatedList() {
    return this.updatedList
  }

  disableClosing() {
    this.closeOption.next(true)
  }

  enableClosing() {
    this.closeOption.next(false)
  }

  closeDialog() {
    this.close.next(true)
  }
}
