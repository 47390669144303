import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/app.constants';
import { createHeaders } from 'src/app/app.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoorDataService {

  constructor(private http: HttpClient) { }

  getdooralllistAccess() : Observable<any> {
    return this.http.get(`${API_URL}/api/doorList`, createHeaders());
  }

  getdooralllist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/door`, createHeaders());
  }

  getdoorlist(dcdId, adId, id) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/door/${id}`, createHeaders());
  }

  _getdoorlist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/door`, createHeaders());
  }

  putdoorlist(dcdId, adId, id, cabinetlist) : Observable<any> {
    return this.http.put(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/door/${id}`, cabinetlist, createHeaders());
  }

  deleteDoor(dcdId, adId, id) : Observable<any> {
    return this.http.delete(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/door/${id}`, createHeaders());
  }

  sendByPassCommand(dcdId, adId, req) {
    return this.http.post(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/door/sendByPassCommand`, req, createHeaders())
  }

  updateDoorGroup(grpSetting) {
    return this.http.put(`${API_URL}/api/doorGroupSetting`, grpSetting, createHeaders())
  }
}
