import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SuperAdminService } from '../super-admin.service';
import { FormBuilder, Validators } from '@angular/forms';
import { openDialog } from 'src/app/warning-dialog/opendialog';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import { CookiesService } from 'src/app/cookies.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../super-admin.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  formData: any
  message: string;

  constructor(public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private superAdminService: SuperAdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private successErrorService: SuccessErrorDialogService,
    private cookiesService: CookiesService
  ) {

    this.data = this.data[0]

    this.dialogRef.disableClose = true

    this.formData = this.formBuilder.group({
      userName: [{value: '', disabled: true}, Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]

    })

  }

  ngOnInit() {
    this.formData.patchValue({
      userName: this.data['email'],
      newPassword: '',
      confirmPassword: ''
    })

  }


  onReset() {
    this.formData.patchValue({
      userName: this.data['email'],
      newPassword: '',
      confirmPassword: ''
    })
  }

  updatePassword(formData) {
    formData.controls['userName'].enable()
    let req = { ...formData.value }

    req['token'] = this.cookiesService.getCookie('token')
    req['token'] = req['token'].split(' ')[1]

    this.superAdminService.resetPasswordBySuperAdmin(req).subscribe(res => {
      this.successErrorService.showMessage('success', 'User password changed successfully')
      this.superAdminService.updateList()

    },
      err => {
        this.successErrorService.showMessage('error', err)
      })
  }

}
