import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { HolidaysService } from '../holidays.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';

import * as $ from 'jquery'

@Component({
  selector: 'app-add-email',
  templateUrl: './add-holiday.component.html',
  styleUrls: ['./add-holiday.component.scss', '../holidays.component.scss']
})
export class AddHolidayComponent implements OnInit {

  formData: any
  message: string;
  minDate = new Date(631152000000)
  maxDate = new Date(2556057600000)

  constructor(public dialogRef: MatDialogRef<AddHolidayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private holidayService: HolidaysService,
    private formBuilder: FormBuilder,
    private successErrorService: SuccessErrorDialogService,
  ) {
    this.dialogRef.disableClose = true

    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required]
    })
  }

  ngOnInit() {

  }

  clear() {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required]
    })
  }

  createHoliday() {
    $('#cover-spin').show()
    let reqBody = { ...this.formData.value }
    let timeInLocal = reqBody['date']
    let timeInUtc = timeInLocal.toString().split('GMT')[0] //Removing timezone from date
    timeInUtc = timeInUtc + 'GMT'
    reqBody['date'] = Math.floor(new Date(timeInUtc).valueOf()/1000)

    this.holidayService.createHoliday(reqBody).subscribe(res => {
      $('#cover-spin').hide()
      if (res) {
        this.message = 'Holiday added successfully'
        setTimeout(() => {
          this.message = ''
          this.dialogRef.close()
          this.holidayService.updateList()
        }, 2000);
      }
    }, err => {
      this.successErrorService.showMessage('error', err)
      $('#cover-spin').hide()
    })
  }

  selectDate(event) {
    let date = this.formData.controls['date'].value
    date = new Date(date).setHours(23)
    date = new Date(date).setMinutes(59)
    this.formData.controls['date'].setValue(new Date(date))
  }

}
