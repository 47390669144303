import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { API_URL } from '../app.constants';
import { createHeaders } from '../app.util';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  listOneUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false)
  listTwoUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false)  

  constructor(private http: HttpClient) { }

  configureDevice(deviceDetail: object) : Observable<any> {
    return this.http.post(`${API_URL}/api/discovery-device`, deviceDetail, createHeaders());
  }

  updateDatabase(dcd_id, ad_id) {
    return this.http.post(`${API_URL}/api/dcd/${dcd_id}/ad/${ad_id}/updateDeviceDb`, null, createHeaders());
  }

  updateListOne() {
    this.listOneUpdated.next(true)
  }

  updateListTwo() {
    this.listTwoUpdated.next(true)
  }

  updateDevice(deviceDetail: object) {
    return this.http.put(`${API_URL}/api/dcd/${deviceDetail['dcdId']}/ad/${deviceDetail['id']}`, deviceDetail, createHeaders())
  }

  resetDevice(adId: Number, resetType: Number, resetSubType: Number) {
    return this.http.post(`${API_URL}/api/fw/ad/${adId}/reset/${resetType}/${resetSubType}`, null, createHeaders())
  }

  restartDevice(adId: Number, restartType: Number) {
    return this.http.post(`${API_URL}/api/fw/ad/${adId}/restart/${restartType}`, null, createHeaders())
  }

  getResponse(adId, fwType) {
    return this.http.get(`${API_URL}/api/fw/ad/${adId}/response/${fwType}`, createHeaders())
  }

  markResponseCancelled(adId, fwType) {
    return this.http.post(`${API_URL}/api/fw/ad/${adId}/cancel/${fwType}`, null, createHeaders())
  }
}
