import { environment } from 'src/environments/environment'

export const API_URL_ = 'https://private-anon-ae52522192-smartdoor.apiary-mock.com';
export var API_URL;
export const TOKEN = 'token';
export const AUTHENTICATED_USER = 'authenticaterUser';
export const DCD_RECEIVED = 'dcd_received';
export const AD_RECEIVED = 'ad_received';
export const SELECTED_AD = 'selected_ad';
export const NO_OF_MODULES = 6

export function setApiUrl(apiUrl) {
    API_URL = apiUrl
}

export const TRIGGER_REASON = [
  {id: 0, name: 'Door Contact'},
  {id: 1, name: 'Rex'},
  {id: 2, name: 'Bypass'},
  {id: 3, name: 'Fire'},
  {id: 4, name: 'Tamper'}
];
export const SENSOR_MODE = [
  {id: 0, name: 'Normally Open'},
  {id: 1, name: 'Normally Close'}
];

export const BATTERY_CHARGING_MODE = [
  {id: 0, name: 'Auto'},
  {id: 1, name: 'Slow'},
  {id: 2, name: 'Normal'},
  {id: 3, name: 'Accelerated'}
];

export const DOOR_ALARM_BEEP_MODE = [
  {id: 0, name: 'No Alarm'},
  {id: 1, name: 'Alarm 1'},
  {id: 2, name: 'Alarm 2'},
  {id: 3, name: 'Alarm 3'},
  {id: 4, name: 'Alarm 4'}
];

export const CABINET_EVENTS = [
  {id: 0, name: 'Send no events'},
  {id: 1, name: 'Send all events'},
  {id: 2, name: 'Send events on state change'},
  {id: 3, name: 'Send access control events'}
]

export const BLE_SENSE = [
  {id: 0, name: 'LOW'},
  {id: 1, name: 'MEDIUM'},
  {id: 2, name: 'HIGH'}
]

export const READER_TYPES = [
  {id: 0, name: 'WEIGAND'},
  {id: 1, name: 'OSDP'}
]

export const READER_SUBTYPES = [
  {id: 1, name: 'CARD Only'},
  {id: 2, name: 'PIN Only'},
  {id: 3, name: 'CARD and PIN'}
]

export const RESET_TYPES = [
    {name : 'ESP', id: 1},
    {name: 'PSOC', id: 2},
    {name: 'ESP&PSOC', id: 3}
]

export const RESTART_TYPES = [
    {name : 'ESP', id: 4},
    {name: 'PSOC', id: 5},
    {name: 'ESP&PSOC', id: 6}
]

export const RESET_SUBTYPES = [
    {name : 'Config', id: 1},
    {name: 'FwImage', id: 2},
    {name: 'Settings', id: 3}
]

export const SUPERVISIONS = [
  {id: 0, name: 'Unsupervised'},
  {id: 1, name: 'Supervised'}
]

export const EVENTS = [
  {id: 0, name: 'Not Allowed'},
  {id: 1, name: 'Allowed'}
]

export const OUTPUT_TYPES = [
  {id: 0, name: 'Dry'},
  {id: 1, name: 'Wet'}
]

export const GATEWAY_OSDPS = [
  {id: 0, name: 'Normal'},
  {id: 1, name: 'Secure'}
]

export const RDR_OSDPS = [
  {id: 0, name: 'Normal'},
  {id: 1, name: 'Secure'}
]

export const US_STATES = [
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
]

export const UNLOCK_MODE = [
    {id: 0, name: 'None'},
    {id: 1, name: 'Rex'},
    {id: 2, name: 'Bypass'},
    {id: 3, name: 'Both'}
]

export const SCROLL_TYPE = [
    {id: 0, name: 'No Scroll'},
    {id: 1, name: 'Auto'},
    {id: 2, name: 'Button'}
]

export const MODULE_TYPES = [
    {type: 1, name: 'Input'},
    {type: 2, name: 'Output'},
    {type: 3, name: 'Cabinet'},
    {type: 4, name: 'Ambient'},
    {type: 5, name: 'Battery'},
    {type: 6, name: 'Door'},
    {type: 7, name: 'Reader'}
]

export const DOWNLOADS_TYPES = [
    {type: 2, name: 'PDF'},
    {type: 2, name: 'EXCEL'},
]

export const DEVICE_TYPES = [
    {id: 0, name: 'SMART_LOCK'},
    {id: 1, name: 'ACCESS_CONTROL'},
    {id: 2, name: 'QPS'},
    {id: 3, name: 'GATEWAY'}
]

export const mediumTypes = [
    {id: 1, name: 'CARD'},
    {id: 2, name: 'MAC'},
    {id: 3, name: 'OTHER'}
]

export const cardSubTypes = [
    {id: 1, name: 'CARD Only'},
    {id: 2, name: 'PIN Only'},
    {id: 3, name: 'CARD and PIN'}
]

export const inputStates = 
    {0: 'Inactive',
    1: 'Active'}

export const inputLines = 
    {0: 'Low',
    1: 'High'}

export const inputReasons = 
    {0: 'Door Contact',
    1: 'Rex',
    2: 'Bypass',
    3: 'Fire',
    4: 'Tamper'}

export const outputStates = 
    {0: 'Inactive',
    1: 'Active'}

export const outputLines = 
    {0: 'Low',
    1: 'High',
    2: 'Error'}

export const readerReasons = {
    1: 'Access Granted',
    2: 'Card Not Found',
    3: 'Invalid Pin',
    4: 'Card Not Active',
    5: 'Invalid Schedule',
    6: 'Invalid Door',
    7: 'Holiday'
}

export const doorReasons = {
    0: "No Value",
    1: "Rex Bypass",
    2: "Card Bypass",
    3:  "Bypass",
    4: "Alarm",
    5: "Held",
    6: "Warning",
    7: "Secure",
    8: "Remote Bypass",
    9: "Key Bypass"
}
export const doorReasonsObj = [
    {item_id: 0, item_text: "No Value"},
    {item_id: 1, item_text: "Rex Bypass"},
    {item_id: 2, item_text: "Card Bypass"},
    {item_id: 3, item_text: "Bypass"},
    {item_id: 4, item_text: "Alarm"},
    {item_id: 5, item_text: "Held"},
    {item_id: 6, item_text: "Warning"},
    {item_id: 7, item_text: "Secure"},
    {item_id: 8, item_text: "Remote Bypass"},
    {item_id: 9, item_text: "Key Bypass"},
]