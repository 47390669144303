import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { HolidaysService } from '../holidays.service';
import { openDialog } from 'src/app/warning-dialog/opendialog';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';

import * as $ from 'jquery'

@Component({
  selector: 'app-edit-holiday',
  templateUrl: './edit-holiday.component.html',
  styleUrls: ['./edit-holiday.component.scss', '../holidays.component.scss']
})
export class EditHolidayComponent implements OnInit {

  formData: any
  minDate = new Date(631152000000)
  maxDate = new Date(2556057600000)

  constructor(public dialogRef: MatDialogRef<EditHolidayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private holidaysService: HolidaysService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private successErrorService: SuccessErrorDialogService,
  ) {

    this.dialogRef.disableClose = true

    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required]
    })

  }

  ngOnInit() {
    this.formData.patchValue({
      name: this.data['name'],
      date: this.getTimeAfterZoneChange(this.data['date'])
    })
  }

  diff_to_GMT() {
    function z(n){return (n<10? '0' : '') + n}
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0? '+' : '-';
    offset = Math.abs(offset);
    return sign + z(offset/60 | 0) + z(offset%60);
  
  }

  getTimeAfterZoneChange(time) {
    let dateWithLocalZone
    return dateWithLocalZone = new Date(new Date(time * 1000).toUTCString() + this.diff_to_GMT())
  }


  deleteHoliday() {
    openDialog(this.dialog, x => {
      if (x) {
        this.holidaysService.deleteHoliday(this.data['id']).subscribe(res => {
          if (res['id'] == this.data['id']) {
            this.holidaysService.updateList()
            this.successErrorService.showMessage('success', 'Holiday deleted successfully')
            setTimeout(() => {
              this.dialogRef.close()

            }, 2000);
          }

        },
          err => {
            this.successErrorService.showMessage('error', err)

          })
      }
    })
  }

  onReset() {
    this.formData.patchValue({
      name: this.data['name'],
      date: this.getTimeAfterZoneChange(this.data['date'])
    })
  }

  updateHoliday(formData) {
    $('#cover-spin').show()
    let req = { ...formData.value }
    let timeInLocal = req['date']
    let timeInUtc = timeInLocal.toString().split('GMT')[0] //Removing timezone from date
    timeInUtc = timeInUtc + 'GMT'
    req['date'] = Math.floor(new Date(timeInUtc).valueOf()/1000)

  
    this.holidaysService.updateHoliday(this.data['id'], req).subscribe(res => {
      if (res['id'] == this.data['id']) {
        this.holidaysService.updateList()
        $('#cover-spin').hide()
        this.successErrorService.showMessage('success', 'Holiday updated successfully')
      }

    },
      err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
      })
  }

  selectDate(event) {
    let date = this.formData.controls['date'].value
    date = new Date(date).setHours(23)
    date = new Date(date).setMinutes(59)
    this.formData.controls['date'].setValue(new Date(date))
  }

}
