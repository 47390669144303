import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { LogoutComponent } from './logout/logout.component';
import { RouteGuardService } from './service/route-guard.service';
import { ErrorComponent } from './error/error.component';
import { SuperAdminGuardService } from './service/super-admin-guard.service';
import { AdminGuardService } from './service/admin-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { path: '', component: SigninComponent },
  { path: 'login', component: SigninComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [RouteGuardService] },
  {
    path: 'schedule',
    loadChildren: () => import('src/app/schedule/schedule.module').then(m => m.ScheduleModule), canActivate: [RouteGuardService]
  },
  {
    path: 'access',
    loadChildren: () => import('src/app/access/access.module').then(m => m.AccessModule), canActivate: [RouteGuardService]
  },
  {
    path: 'email',
    loadChildren: () => import('src/app/email/email.module').then(m => m.EmailModule), canActivate: [RouteGuardService]
  },
  {
    path: 'email-config',
    loadChildren: () => import('src/app/email-config/email-config.module').then(m => m.EmailConfigModule), canActivate: [RouteGuardService]
  },
  {
    path: 'user',
    loadChildren: () => import('src/app/user/user.module').then(m => m.UserModule), canActivate: [RouteGuardService]
  },
  {
    path: 'holidays',
    loadChildren: () => import('src/app/holidays/holidays.module').then(m => m.HolidaysModule), canActivate: [RouteGuardService]
  },
  {
    path: 'facility',
    loadChildren: () => import('src/app/facility/facility.module').then(m => m.FacilityModule), canActivate: [RouteGuardService]
  },
  {
    path: 'admin',
    loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuardService]
  },
  {
    path: 'superadmin',
    loadChildren: () => import('src/app/super-admin/super-admin.module').then(m => m.SuperAdminModule), canActivate: [SuperAdminGuardService]
  },
  {
    path: 'settings',
    loadChildren: () => import('src/app/settings/settings.module').then(m => m.SettingsModule), canActivate: [RouteGuardService]
  },
  {
    path: 'doors',
    loadChildren: () => import('src/app/doors/doors.module').then(m => m.DoorsModule), canActivate: [RouteGuardService]
  },
  {
    path: 'events',
    loadChildren: () => import('src/app/events/events.module').then(m => m.EventsModule), canActivate: [RouteGuardService]
  },
  {
    path: 'doorGroup',
    loadChildren: () => import('src/app/door-group/door-group.module').then(m => m.DoorGroupModule), canActivate: [RouteGuardService]
  },
  {
    path: 'analytics',
    loadChildren: () => import('src/app/analytics/analytics.module').then(m => m.AnalyticsModule), canActivate: [RouteGuardService]
  },
  { path: 'logout', component: LogoutComponent, canActivate: [RouteGuardService] },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
