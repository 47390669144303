import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningDialogComponent } from './warning-dialog.component';

export function openDialog(dialog, callback) {
    const dialogRef = dialog.open(WarningDialogComponent, {
        width: '300px',
        position: {'top': '40px'}
    });
    dialogRef.afterClosed().subscribe(result => {
        callback(result);
    });
}