import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-success-error-dialog',
  templateUrl: './success-error-dialog.component.html',
  styleUrls: ['./success-error-dialog.component.css']
})
export class SuccessErrorDialogComponent implements OnInit {

  message = ''
  type = ''
  constructor(public dialogRef: MatDialogRef<SuccessErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.message = this.data['message']
    this.type = this.data['messageType']
    setTimeout(() => {
      this.dialogRef.close()
    }, 1600);
  }

}
