import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  openNav: BehaviorSubject<boolean> = new BehaviorSubject(false)
  constructor() { }

  setOpenNav(value) {
    this.openNav.next(value);
  }
}
