import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModulePopupService } from '../service/module-popup.service';

@Component({
  selector: 'dialoger',
  templateUrl: './module-popup.component.html',
  styleUrls: ['./module-popup.component.css']
})
export class ModulePopup{

  disableCloseBtn = false

  constructor(
    public dialogRef: MatDialogRef<ModulePopup>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogerService: ModulePopupService) {
      this.dialogRef.disableClose = true

      this.dialogerService.closeOption.subscribe(x => {
        if (x) {
          this.disableCloseBtn = true
        } else {
          this.disableCloseBtn = false
        }
      })
      
      dialogerService.close.subscribe(x => {
        if(x)
          dialogRef.close()
      })
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  shareUpdatedList(list) {
    this.dialogerService.updateList(list)
  }

}