import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CabinetDataService } from 'src/app/service/data/form/cabinet-data.service';
import { ModulePopupService } from 'src/app/service/module-popup.service';
import { CABINET_EVENTS, BLE_SENSE, GATEWAY_OSDPS, RDR_OSDPS, EVENTS, SCROLL_TYPE } from 'src/app/app.constants';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-cabinet-settings',
  templateUrl: './cabinet-settings.component.html',
  styleUrls: ['./cabinet-settings.component.css']
})
export class CabinetSettingsComponent implements OnInit {

  @Input() cabinetDetail: any;
  @Output() updatedList = new EventEmitter<any>()
  @Input() dcdId: number;
  @Input() adId: number;
  cabinetForm: FormGroup;
  events = []
  minDate = new Date(631152000000)
  maxDate = new Date(2556057600000)
  bleSense = []
  Id = ''
  gatewayOsdps: { id: number; name: string; }[];
  rdrOsdps: { id: number; name: string; }[];
  scrollTypes: { id: number; name: string; }[];
  @Input() connected

  constructor(private fb: FormBuilder,
    private cabinetdataservice: CabinetDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogerService: ModulePopupService,
    private successErrorService: SuccessErrorDialogService) {
    this.createForm({});
    this.events = EVENTS
    this.bleSense = BLE_SENSE
    this.gatewayOsdps = GATEWAY_OSDPS
    this.rdrOsdps = RDR_OSDPS
    this.scrollTypes = SCROLL_TYPE
  }

  ngOnInit() { 
    this.createForm(this.cabinetDetail)
    this.Id = this.cabinetDetail['id']
    this.selectCurrentDateAndTime()
  }

  createForm(obj) {

    this.cabinetForm = this.fb.group({
      name: [obj.name || `Cabinet`, Validators.required],
      state: [obj.state ? true : false, Validators.required],
      displayIntensity: [(obj.displayIntensity >= 10 && obj.displayIntensity <=100 ) ? obj.displayIntensity : 10, Validators.compose([
        Validators.required, Validators.min(10), Validators.max(100)])],
      scrollTime: [(obj.scrollTime >= 0 && obj.scrollTime <=100 ) ? obj.scrollTime : 0, Validators.compose([
        Validators.required, Validators.min(0), Validators.max(100)])],
      ble: [obj.ble ? true : false, Validators.required],
      time: [obj.time ? this.getTimeAfterZoneChange(obj['time']) : new Date(), Validators.required],
      bleSense: [obj.bleSense ? obj.bleSense : 0 , Validators.required],
      gatewayOsdp: [obj.gatewayOsdp ? obj.gatewayOsdp : 0, Validators.required],
      rdrOsdp: [obj.rdrOsdp ? obj.rdrOsdp : 0, Validators.required],
      event: [obj.event ? obj.event : 0, Validators.required],
      scrollType: [obj.scrollType, Validators.required],
      bleAccess: [obj.bleAccess ? true : false, Validators.required]
    });
  }

  selectCurrentDateAndTime() {
    this.cabinetForm.controls['time'].setValue(new Date())
  }

  diff_to_GMT() {
      function z(n){return (n<10? '0' : '') + n}
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset/60 | 0) + z(offset%60);
    
  }

  getTimeAfterZoneChange(time) {
    let dateWithLocalZone
    return dateWithLocalZone = new Date(new Date(time * 1000).toUTCString() + this.diff_to_GMT())
  }

  getallinputfromdata(dcdId, adsId) {
    return this.cabinetdataservice._getcabinetlist(dcdId, adsId)
      .subscribe(response => {
        this.cabinetDetail = response['list'].find(x => x['id'] == this.Id)
        this.createForm(this.cabinetDetail)
        this.updatedList.emit(response)
      },
        errors => {
          this.successErrorService.showMessage('error', errors)
        });
  }

  saveCabinet(id, cabinetData, cabinetForm) {
    $('#cover-spin').show()
    this.dialogerService.disableClosing()
    cabinetData = { ...cabinetData, ...cabinetForm.value };
    cabinetData['state'] = cabinetData['state'] ? 1 : 0;
    cabinetData['ble'] = cabinetData['ble'] ? 1 : 0;
    cabinetData['bleAccess'] = cabinetData['bleAccess'] ? 1 : 0;


    let timeInLocal = cabinetData['time']
    let timeInUtc = timeInLocal.toString().split('GMT')[0] //Removing timezone from date
    timeInUtc = timeInUtc + 'GMT'

    cabinetData['time'] = Math.floor(new Date(timeInUtc).valueOf()/1000)
    
    return this.cabinetdataservice.putcabinetlist(this.dcdId, this.adId, id, cabinetData).subscribe(
      data => {
        $('#cover-spin').hide()
        if(this.connected) {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Sent.')
        } else {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Not Sent.')
        }
        setTimeout(() => {
          this.getallinputfromdata(this.dcdId, this.adId);
          this.dialogerService.enableClosing()
        }, 2000);
      },err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
        this.dialogerService.enableClosing()
      }
    );
  }
}
