import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AdminService } from '../admin.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FirmwareUploadService } from 'src/app/service/firmware-upload.service';
import { EventSourcePolyfill } from 'ng-event-source';
import { API_URL, RESET_SUBTYPES, RESET_TYPES, RESTART_TYPES } from 'src/app/app.constants';
import * as $ from 'jquery';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import { CookiesService } from 'src/app/cookies.service';

@Component({
  selector: 'app-update-device',
  templateUrl: './update-device.component.html',
  styleUrls: ['./update-device.component.scss']
})
export class UpdateDeviceComponent implements OnInit, OnDestroy {

  deviceName = ''
  signature = ''
  formData: any
  disableAnimation = true
  selectedPsoc: any
  selectedEsp: any
  eventSourceFW: any;
  eventSourceDB: any
  uploadingPsoc = 0
  uploadingEsp = 0
  message = ''
  espBtn = 1;
  psocBtn = 1;
  espUploadProgress = 0
  psocUploadProgress = 0
  updatingDatabase = false
  updatedDatabase = false
  updateDatabaseProgress = 0
  psocTimeout = null
  espTimeout = null
  statusTimeout: any;
  resetType = 1
  resetSubType = 1
  resetTypes = []
  resetSubTypes = []
  restartType  = 4
  restartTypes = []

  constructor(public dialogRef: MatDialogRef<UpdateDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private firmwareUploadService: FirmwareUploadService,
    private successErrorService: SuccessErrorDialogService,
    private cookiesService: CookiesService) {

    this.formData = this.formBuilder.group({
      deviceName: ['', Validators.required],
      signature: ['', Validators.required]
    })

    this.resetTypes = RESET_TYPES
    this.restartTypes = RESTART_TYPES
    this.resetSubTypes = RESET_SUBTYPES
  }

  ngOnInit() {
    this.formData.patchValue({
      deviceName: this.data['name'],
      signature: this.data['signature'],
    })
  }


  getResponse() {
    $('#cover-spin').show()

    let espResponse
    let psocResponse

    let count = 0

    this.adminService.getResponse(this.data['id'], 'esp').subscribe(res => {
      if (res) {
        espResponse = res

        if (espResponse['response'] == 3 && !espResponse['cancelled']) {
          this.selectedEsp = true
          this.espBtn = 2
          $('#esp-btn').text('cancel')
          this.uploadingEsp = 1
          this.espUploadProgress = espResponse['progress']
          this.getUploadStatus()
        }

        count++

        if (count == 2)
          $('#cover-spin').hide()
      }

    },
      err => {

        count++

        if (count == 2)
          $('#cover-spin').hide()

      })

    this.adminService.getResponse(this.data['id'], 'psoc').subscribe(res => {
      if (res)
        psocResponse = res

      if (psocResponse['response'] == 3 && !psocResponse['cancelled']) {
        this.selectedPsoc = true
        this.psocBtn = 2
        $('#psoc-btn').text('cancel')
        this.uploadingPsoc = 1
        this.psocUploadProgress = psocResponse['progress']
        this.getUploadStatus()
      }

      count++

      if (count == 2)
        $('#cover-spin').hide()

    },
      err => {

        count++

        if (count == 2)
          $('#cover-spin').hide()

      })
  }


  selectFile(event, type) {
    let fileName = event['target']['files'][0]['name']
    if (fileName.slice(fileName.length - 3) != 'bin') {
      this.successErrorService.showMessage('error', 'Only bin type files allowed')
      return
    }
    fileName = fileName.substring(0, 15) + (fileName.length > 15 ? "..." : "")
    if (type == 'psoc') {
      this.selectedPsoc = event['target']['files'][0];
      $("#label-1").text(fileName);
      this.psocBtn = 1
      this.psocUploadProgress = 0
      this.uploadingPsoc = 0
    } else if (type == 'esp') {
      this.selectedEsp = event['target']['files'][0]
      $('#label-2').text(fileName)
      this.espBtn = 1
      this.espUploadProgress = 0
      this.uploadingEsp = 0
    }

  }

  uploadPsoc() {

    if (this.psocBtn == 2) {
      clearTimeout(this.statusTimeout)
      this.adminService.markResponseCancelled(this.data['id'], 'psoc').subscribe(
        res => {
          this.psocBtn = 1
          this.uploadingPsoc = 0
          this.psocUploadProgress = 0
          if (this.psocUploadProgress != 0) {
            clearTimeout(this.psocTimeout)
          }
          $('#psoc-btn').text('upload')
          return
        },
        err => { })
    } else {
      this.psocBtn = 2
      $('#psoc-btn').text('cancel')
      this.uploadingPsoc = 0
      this.psocUploadProgress = 0
      if (this.eventSourceFW)
        this.eventSourceFW.close()

      let reqCancelled = false

      this.psocTimeout = setTimeout(() => {
        if (!this.uploadingPsoc) {
          if (this.psocBtn == 2) {
            $('#psoc-btn').text('upload')
            this.psocBtn = 1
            this.uploadingPsoc = 0
          }

          reqCancelled = true
          this.successErrorService.showMessage('error', 'Its taking too long to upload. Try uploading again.')
        }
      }, 30000);

      this.firmwareUploadService.uploadPsoc(this.selectedPsoc, this.data['id']).subscribe(res => {
        if (!reqCancelled) {
          this.uploadingPsoc = 1
          this.getUploadStatus()
        }

      },
        err => {
          this.successErrorService.showMessage('error', err)
        })
    }
  }

  uploadEsp() {
    if (this.espBtn == 2) {
      clearTimeout(this.statusTimeout)
      this.adminService.markResponseCancelled(this.data['id'], 'esp').subscribe(
        res => {
          this.espBtn = 1
          this.uploadingEsp = 0
          this.espUploadProgress = 0
          if (this.espUploadProgress == 0) {
            clearTimeout(this.espTimeout)
          }
          $('#esp-btn').text('upload')
          return
        },
        err => { })
    } else {
      this.espBtn = 2
      $('#esp-btn').text('cancel')
      this.uploadingEsp = 0
      this.espUploadProgress = 0
      if (this.eventSourceFW)
        this.eventSourceFW.close()

      let reqCancelled = false

      this.espTimeout = setTimeout(() => {
        if (!this.uploadingEsp) {
          if (this.espBtn == 2) {
            $('#esp-btn').text('upload')
            this.espBtn = 1
            this.uploadingEsp = 0
          }

          reqCancelled = true

          this.successErrorService.showMessage('error', 'Its taking too long to upload. Try uploading again.')
        }
      }, 30000);

      this.firmwareUploadService.uploadEsp(this.selectedEsp, this.data['id']).subscribe(res => {

        if (!reqCancelled) {
          this.uploadingEsp = 1
          this.getUploadStatus()
        }

      },
        err => {
          this.successErrorService.showMessage('error', err)
        })
    }
  }

  getUploadStatus() {

    let eventData = null
    let reqCancelled = false

    this.eventSourceFW = new EventSourcePolyfill(API_URL + '/api/sse/firmware-events', {
      heartbeatTimeout: 1800000,
      connectionTimeout: 360000,
      headers: {
        'authorization': this.cookiesService.getCookie('token')
      }
    });

    this.statusTimeout = setTimeout(() => {
      if (!eventData) {
        if (this.espBtn == 2) {
          $('#esp-btn').text('upload')
          this.espBtn = 1
          this.uploadingEsp = 0
          this.eventSourceFW.close()
        } else if (this.psocBtn == 2) {
          $('#psoc-btn').text('upload')
          this.psocBtn = 1
          this.uploadingPsoc = 0
          this.eventSourceFW.close()
        }

        reqCancelled = true
        this.successErrorService.showMessage('error', 'Its taking too long to get upload status. Try uploading again.')
      }
    }, 60000);

    this.eventSourceFW.onmessage = (event) => {
      if (!reqCancelled) {
        eventData = JSON.parse(event['data'])
        if (eventData['adId'] != this.data['id'])
          return

        if (eventData['fwType'] == 'psoc') {
          this.psocUploadProgress = eventData['progress']
        } else if (eventData['fwType'] == 'esp') {
          this.espUploadProgress = eventData['progress']
        }


        if (eventData['response'] == 1) {
          if (eventData['fwType'] == 'psoc') {
            this.uploadingPsoc = 2
            this.psocUploadProgress = 100
            this.psocBtn = 1
            $('#psoc-btn').text('upload')
          } else if (eventData['fwType'] == 'esp') {
            this.uploadingEsp = 2
            this.espUploadProgress = 100
            this.espBtn = 1
            $('#esp-btn').text('upload')
          }

        } else if (eventData['response'] == 4) {
          clearTimeout(this.statusTimeout)
          if (eventData['fwType'] == 'psoc') {
            this.adminService.markResponseCancelled(this.data['id'], 'psoc').subscribe(
              res => {
                this.psocBtn = 1
                this.uploadingPsoc = 0
                this.psocUploadProgress = 0
                if (this.psocUploadProgress == 0) {
                  clearTimeout(this.psocTimeout)
                }
                $('#psoc-btn').text('upload')
                this.successErrorService.showMessage('error', 'Cancelled from the device. Please try uploading again.')
                return
              },
              err => { })
          } else if (eventData['fwType'] == 'esp') {
            this.adminService.markResponseCancelled(this.data['id'], 'esp').subscribe(
              res => {
                this.espBtn = 1
                this.uploadingEsp = 0
                this.espUploadProgress = 0
                if (this.espUploadProgress == 0) {
                  clearTimeout(this.espTimeout)
                }
                $('#esp-btn').text('upload')
                this.successErrorService.showMessage('error', 'Cancelled from the device. Please try with another version.')
                return
              },
              err => { })
          }

        }
      }

    }
  }

  getUpdateDeviceDBStatus() {

    let eventData = null
    let eventCancelled = false

    setTimeout(() => {
      if (!eventData) {
        this.updateDatabaseProgress = 0
        this.updatedDatabase = false
        this.updatingDatabase = false
        this.eventSourceDB.close()
        eventCancelled = true
        this.successErrorService.showMessage('error', 'Its taking too long to get update status. Try updating again.')
      }
    }, 60000);

    this.eventSourceDB = new EventSourcePolyfill(API_URL + '/api/sse/update-device-db-events', {
      heartbeatTimeout: 1800000,
      connectionTimeout: 360000,
      headers: {
        'authorization': this.cookiesService.getCookie('token')
      }
    });


    this.eventSourceDB.onmessage = (event) => {

      if (!eventCancelled) {

        eventData = JSON.parse(event['data'])

        if (eventData['id'] != this.data['id'])
          return

        if (this.updatedDatabase == false)
          this.updateDatabaseProgress = eventData['progress']


        if (eventData['response'] == 1 && this.updatedDatabase == false) {
          this.updateDatabaseProgress = 100
          this.updatedDatabase = true
        }
      }
    }

  }

  updateDevice() {
    $('#cover-spin').show()
    let deviceDetail = this.data

    deviceDetail['name'] = this.formData['controls']['deviceName']['value']
    this.adminService.updateDevice(deviceDetail).subscribe(res => {
      $('#cover-spin').hide()
      if (res) {
        this.message = 'Device updated successfully'
        this.adminService.updateListOne()
        setTimeout(() => {
          this.message = ''
          this.dialogRef.close()
        }, 2000);
      }
      console.log(res)
    },
      err => {
        this.successErrorService.showMessage('error', err)
        $('#cover-spin').hide()
      })
  }

  updateDatabase() {
    $('#cover-spin').show()
    this.updateDatabaseProgress = 0
    this.updatedDatabase = false
    this.updatingDatabase = false
    if (this.eventSourceDB)
      this.eventSourceDB.close()

    let reqCancelled = false

    setTimeout(() => {
      if (!this.updatingDatabase) {
        console.log('Cancelling update db')
        reqCancelled = true
        this.updateDatabaseProgress = 0
        this.updatedDatabase = false
        this.updatingDatabase = false
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', 'Its taking too long to update db. Try updating again.')
      }
    }, 30000);
    this.adminService.updateDatabase(this.data['dcdId'], this.data['id']).subscribe(res => {

      if (!reqCancelled) {
        this.updatingDatabase = true
        $('#cover-spin').hide()
        this.getUpdateDeviceDBStatus()
      }

    },
      err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
      })
  } 

  resetDevice() {
      $('#cover-spin').show()

      if(this.resetType == 1) {

        this.adminService.resetDevice(this.data['id'], this.resetType, this.resetSubType).subscribe(res => {
          $('#cover-spin').hide()
          this.successErrorService.showMessage('success', 'Esp reset done successfully.')
        }),
          err => {
            $('#cover-spin').hide()
            this.successErrorService.showMessage('error', 'Failed to reset Esp.')
          }
      } else if(this.resetType == 2) {

        this.adminService.resetDevice(this.data['id'], this.resetType, this.resetSubType).subscribe(res => {
          $('#cover-spin').hide()
          this.successErrorService.showMessage('success', 'Psoc reset done successfully.')
        }),
          err => {
            $('#cover-spin').hide()
            this.successErrorService.showMessage('error', 'Failed to reset Psoc.')
          }
      } else if(this.resetType == 3) {

        this.adminService.resetDevice(this.data['id'], this.resetType, this.resetSubType).subscribe(res => {
          $('#cover-spin').hide()
          this.successErrorService.showMessage('success', 'Esp and Psoc reset done successfully.')
        }),
          err => {
            $('#cover-spin').hide()
            this.successErrorService.showMessage('error', 'Failed to reset Esp and Psoc.')
          }
      }
  }

  restartDevice() {
    $('#cover-spin').show()

    if(this.restartType == 4) {

      this.adminService.restartDevice(this.data['id'], this.restartType).subscribe(res => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('success', 'Esp restart done successfully.')
      }),
        err => {
          $('#cover-spin').hide()
          this.successErrorService.showMessage('error', 'Failed to restart Esp.')
        }
    } else if(this.restartType == 5) {

      this.adminService.restartDevice(this.data['id'], this.restartType).subscribe(res => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('success', 'Psoc restart done successfully.')
      }),
        err => {
          $('#cover-spin').hide()
          this.successErrorService.showMessage('error', 'Failed to restart Psoc.')
        }
    } else if(this.restartType == 6) {

      this.adminService.restartDevice(this.data['id'], this.restartType).subscribe(res => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('success', 'Esp and Psoc restart done successfully.')
      }),
        err => {
          $('#cover-spin').hide()
          this.successErrorService.showMessage('error', 'Failed to restart Esp and Psoc.')
        }
    }
}

  ngOnDestroy() {
    if (this.eventSourceDB)
      this.eventSourceDB.close()

    if (this.eventSourceFW)
      this.eventSourceFW.close()
  }
}
