import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InputDataService } from 'src/app/service/data/form/input-data.service';
import { SENSOR_MODE, TRIGGER_REASON, SUPERVISIONS, EVENTS } from 'src/app/app.constants';
import { ModulePopupService } from 'src/app/service/module-popup.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-input-settings',
  templateUrl: './input-settings.component.html',
  styleUrls: ['./input-settings.component.css']
})

export class InputSettingsComponent implements OnInit {

  @Input() inputDetail: any;
  @Input() dcdId: number;
  @Input() adId: number;
  @Output() updatedList = new EventEmitter<any>()
  Id: number;
  inputForm: FormGroup;
  Triggers = [];
  Sensors = [];
  supervisions: { id: number; name: string; }[];
  events: { id: number; name: string; }[];
  @Input() connected

  constructor(private fb: FormBuilder,
    private inputdataservice: InputDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogerService: ModulePopupService,
    private successErrorService: SuccessErrorDialogService) {
    this.createForm({});
    this.Triggers = TRIGGER_REASON;
    this.Sensors = SENSOR_MODE;
    this.supervisions = SUPERVISIONS
    this.events = EVENTS
  }

  ngOnInit() {
    this.createForm(this.inputDetail)
    this.Id = this.inputDetail['id']
  }

  createForm(obj) {
    this.inputForm = this.fb.group({
      name: [obj.name ? obj.name : 'Input', Validators.required],
      state: [obj.state ? true : false, Validators.required],
      triggerReason: [obj.triggerReason ? obj.triggerReason : 0, Validators.required],
      sensorMode: [(obj.sensorMode == 0 || obj.sensorMode == 1 ) ? obj.sensorMode : 0, Validators.required],
      debounceTime: [(obj.debounceTime >= 100 && obj.debounceTime <=1000 ) ? obj.debounceTime : 100],
      supervision: [obj.supervision ? obj.supervision : 0],
      register1: [obj.register1 ? obj.register1 : 0],
      register2: [obj.register2 ? obj.register1 : 0],
      event: [obj.event ? obj.event : 0]
    });
  }

  getallinputfromdata(dcdId, adsId) {
    return this.inputdataservice._getinputalllist(dcdId, adsId)
      .subscribe(response => {
        this.inputDetail = response['list'].find(x => x['id'] == this.Id)
        this.updatedList.emit(response)
      },
        errors => {
          this.successErrorService.showMessage('error', errors)
        });
  }

  saveInput(id, inputData, inputForm) {
    $('#cover-spin').show()
    this.dialogerService.disableClosing()
    inputData = { ...inputData, ...inputForm.value };
    inputData['state'] = inputData['state'] ? 1 : 0;
    inputData['triggerReason'] = +inputData['triggerReason'];
    inputData['sensorMode'] = +inputData['sensorMode'];
    inputData['debounceTime'] = +inputData['debounceTime']
    return this.inputdataservice.putinputlist(this.dcdId, this.adId, id, inputData).subscribe(
      data => {
        $('#cover-spin').hide()
        if(this.connected) {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Sent.')
        } else {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Not Sent.')
        }
        setTimeout(() => {
          this.getallinputfromdata(this.dcdId, this.adId);
          this.dialogerService.enableClosing()
        }, 2000);
      },err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
        this.dialogerService.enableClosing()
      }
    );
  }
}
