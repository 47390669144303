import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DoorDataService } from 'src/app/service/data/form/door-data.service';
import { DOOR_ALARM_BEEP_MODE, EVENTS, UNLOCK_MODE } from 'src/app/app.constants';
import { ModulePopupService } from 'src/app/service/module-popup.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-door-settings',
  templateUrl: './door-settings.component.html',
  styleUrls: ['./door-settings.component.css']
})
export class DoorSettingsComponent implements OnInit {

  @Input() doorData: any[];
  @Input() dcdId: number;
  @Input() adId: number;
  doorForm: FormGroup;
  @Output() updatedList = new EventEmitter<any>()
  AlarmModes = [];
  doorDetail = []
  doorContacts = []
  doorInputs = []
  doorOutputs = []
  readers = []
  Id = ''
  events: { id: number; name: string; }[];
  unlockModes: { id: number; name: string; }[];
  @Input() connected: Boolean

  constructor(private fb: FormBuilder,
    private doordataservice: DoorDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogerService: ModulePopupService,
    private successErrorService: SuccessErrorDialogService) {
    this.createForm({});
    this.AlarmModes = DOOR_ALARM_BEEP_MODE;
    this.unlockModes = UNLOCK_MODE;
    this.events = EVENTS

  }

  ngOnInit() {
    this.doorDetail = this.doorData[0]
    this.doorInputs = this.doorData[1]
    this.doorContacts = this.doorData[2]
    this.doorOutputs = this.doorData[3]
    this.readers = this.doorData[4]
    this.createForm(this.doorDetail)
    this.Id = this.doorDetail['id']
  }

  createForm(obj) {
    this.doorForm = this.fb.group({
      name: [obj.name || `Door`, Validators.required],
      state: [obj.state ? true : false, Validators.required],
      bypassTime: [obj.bypassTime || 0, Validators.required ],
      heldTime: [obj.heldTime || 0, Validators.required ],
      warningTime: [obj.warningTime || 0, Validators.required ],
      alarmTime: [obj.alarmTime || 0, Validators.required ],
      alarmBeeperMode: [ obj.alarmBeeperMode ? obj.alarmBeeperMode : 0, Validators.required],
      unlockMode: [obj.unlockMode, Validators.required],
      doorInputs: [ obj.rexInputMinor ? obj.rexInputMinor : 0],
      doorContact: [ obj.doorContact ? obj.doorContact : 0],
      readerId: [ obj.readerId ? obj.readerId : 0],
      event: [ obj.event ? obj.event : 0],
    });

    let binaryDoorOutputValue = this.binaryCodeDecoder(obj.doorOutput)
    if(this.doorOutputs.length > 0) {
      this.doorOutputs.forEach((x, i) => {
        this.doorForm.addControl('doorOutput' + (i + 1), this.fb.control(binaryDoorOutputValue[x['number']-1]))
      })
    }

    let binaryDoorInputValue = this.binaryCodeDecoder(obj.doorInputs)
    if(this.doorInputs.length > 0) {
      this.doorInputs.forEach((x, i) => {
        this.doorForm.addControl('doorInput' + (i + 1), this.fb.control(binaryDoorInputValue[x['number']-1]))
      })
    }
  }

  binaryCodeDecoder(value) {
    let binaryValue = []
    while(value > 0) {
      binaryValue.push(value % 2)
      value = Math.floor(value / 2)
    }
    return binaryValue
  }

  getallinputfromdata(dcdId, adsId, isDeleted) {
    return this.doordataservice._getdoorlist(dcdId, adsId)
      .subscribe(response => {
        this.updatedList.emit(response)
        if(isDeleted) {
          this.dialogerService.closeDialog()
          this.dialogerService.close.next(false)
        } else {
          this.doorDetail = response['list'].find(x => x['id'] == this.Id)
          this.createForm(this.doorDetail)
        }
      },
        errors => {
          this.successErrorService.showMessage('error', errors)
        });
  }

  saveDoor(id, doorData, doorForm) {
    $('#cover-spin').show()
    this.dialogerService.disableClosing()
    doorData = { ...doorData, ...doorForm.value };
    doorData['state'] = doorData['state'] ? 1 : 0;
    doorData['alarmBeeperMode'] = +doorData['alarmBeeperMode'];
    let decimalValue = 0
    this.doorOutputs.forEach((x,i) => {
      decimalValue = decimalValue + Math.pow(2, x['number']-1) * doorData['doorOutput' + (i + 1)]
      delete doorData['doorOutput' + (i + 1)]
    })
   
    doorData['doorOutput'] = decimalValue

    decimalValue = 0
    this.doorInputs.forEach((x,i) => {
      decimalValue = decimalValue + Math.pow(2, x['number']-1) * doorData['doorInput' + (i + 1)]
      delete doorData['doorInput' + (i + 1)]
    })
   
    doorData['doorInputs'] = decimalValue

    return this.doordataservice.putdoorlist(this.dcdId, this.adId, id, doorData).subscribe(
      data => {
        $('#cover-spin').hide()
        if(this.connected) {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Sent.')
        } else {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Not Sent.')
        }
        setTimeout(() => {
          this.getallinputfromdata(this.dcdId, this.adId, false);
          this.dialogerService.enableClosing()
        }, 2000);
      },err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
        this.dialogerService.enableClosing()
      }
    );
  }

  deleteDoor() {

    $('#cover-spin').show()
    this.dialogerService.disableClosing()

    this.doordataservice.deleteDoor(this.dcdId, this.adId, this.Id).subscribe(data => {
      $('#cover-spin').hide()
      this.successErrorService.showMessage('success', 'Door deleted successfully.')
      setTimeout(() => {
        this.getallinputfromdata(this.dcdId, this.adId, true);
        this.dialogerService.enableClosing()
      }, 2000);
    },err => {
      $('#cover-spin').hide()
      this.successErrorService.showMessage('error', err)
      this.dialogerService.enableClosing()
    }
    )
  } 
}
