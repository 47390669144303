import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/app.constants';
import { createHeaders } from 'src/app/app.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CabinetDataService {

  constructor(private http: HttpClient) { }

  getcabinetalllist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/cabinet`, createHeaders());
  }

  getcabinetlist(dcdId, adId, id) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/cabinet/${id}`, createHeaders());
  }

  _getcabinetlist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/cabinet`, createHeaders());
  }

  putcabinetlist(dcdId, adId, id, cabinetlist) : Observable<any> {
    return this.http.put(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/cabinet/${id}`, cabinetlist, createHeaders());
  }
}
