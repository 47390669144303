import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReaderDataService } from 'src/app/service/data/form/reader-data.service';
import { ModulePopupService } from 'src/app/service/module-popup.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import { READER_TYPES, READER_SUBTYPES, EVENTS } from 'src/app/app.constants';
import * as $ from 'jquery'

@Component({
  selector: 'app-reader-settings',
  templateUrl: './reader-settings.component.html',
  styleUrls: ['./reader-settings.component.css']
})
export class ReaderSettingsComponent implements OnInit {

  @Input() readerDetail: any;
  @Input() dcdId: number;
  @Input() adId: number;
  @Output() updatedList = new EventEmitter<any>()
  readerForm: FormGroup;
  readerTypes = []
  subTypes = []
  Id = ''
  events: { id: number; name: string; }[];
  @Input() connected

  constructor(private fb: FormBuilder,
    private readerdataservice: ReaderDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogerService: ModulePopupService,
    private successErrorService: SuccessErrorDialogService) {
      this.events = EVENTS
  }

  ngOnInit() {
    this.readerTypes = READER_TYPES
    this.subTypes = READER_SUBTYPES
    this.createForm(this.readerDetail)
    this.Id = this.readerDetail['id']
    this.readerForm.controls['readerType'].valueChanges.subscribe(v => {
      if (v == 1)
        this.readerForm.controls['readerAdd'].enable()
      else {
        this.readerForm.controls['readerAdd'].disable()
        this.readerForm.controls['readerAdd'].patchValue(0)
      }
    })
  }

  createForm(obj) {
    this.readerForm = this.fb.group({
      name: [obj.name || `Reader`, Validators.required],
      state: [obj.state ? true : false, Validators.required],
      readerType: [obj.readerType, Validators.required],
      subType: [obj.subType, Validators.required],
      event: [obj.event ? obj.event : 0],
      readerAdd: [{value: obj.readerAdd ? obj.readerAdd : 0, disabled: obj.readerType != 1}]
    });
  }


  getallreaderfromdata(dcdId, adsId) {
    return this.readerdataservice._getreaderlist(dcdId, adsId)
      .subscribe(response => {
        this.readerDetail = response['list'].find(x => x['id'] == this.Id)
        this.createForm(this.readerDetail)
        this.updatedList.emit(response)
      },
        errors => {
          this.successErrorService.showMessage('error', errors)
        });
  }

  saveReader(id, readerData, readerForm) {
    $('#cover-spin').show()
    this.dialogerService.disableClosing()
    readerData = { ...readerData, ...readerForm.value };
    readerData['state'] = readerData['state'] ? 1 : 0;
    readerData['readerAdd'] = readerData['readerType'] != 1 ? 0 : readerData['readerAdd']

    return this.readerdataservice.putreaderlist(this.dcdId, this.adId, id, readerData).subscribe(
      data => {
        $('#cover-spin').hide()
        if(this.connected) {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Sent.')
        } else {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Not Sent.')
        }
        setTimeout(() => {
          this.getallreaderfromdata(this.dcdId, this.adId);
          this.dialogerService.enableClosing()
        }, 2000);
      },
      err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
        this.dialogerService.enableClosing()
      }
    );
  }
}
