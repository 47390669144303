import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { Location, PlatformLocation } from "@angular/common";
import { DCD_RECEIVED, AUTHENTICATED_USER, API_URL } from '../app.constants';
import { MainService } from '../service/main.service';
import { CookiesService } from '../cookies.service';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class MainComponent implements OnInit, OnDestroy {


  opened = true;
  over = 'side';
  smallScreen: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 1080;

  constructor(
    private authenticationService: AuthenticationService,
    private location: Location,
    private platformLocation: PlatformLocation,
    private mainService: MainService,
    private cookiesService: CookiesService
  ) {

    this.smallScreen = this.width < this.mobileWidth;

    if (this.cookiesService.getCookie('token') && this.cookiesService.getCookie('SESSION_TIME_LEFT')) {
      this.authenticationService.executeJWTRegenerateTokenService()
    }
  }

  ngOnInit() {

    this.platformLocation.onPopState(() => {
      if (this.location.path().includes('/login'))
        this.authenticationService.logout()
    })
  }

  openSideNav() {
    this.opened = !this.opened
    this.mainService.setOpenNav(this.opened)
  }


  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.smallScreen = this.width < this.mobileWidth;
    if (this.smallScreen) {
      this.opened = false
      this.over = 'over'
      this.mainService.setOpenNav(this.opened)
    } else {
      this.opened = true
      this.over = 'side'
      this.mainService.setOpenNav(this.opened)
    }
  }

  isUserLoggedIn() {
    return this.authenticationService.isUserLoggedIn()
  }

  getRole() {
    const role = this.authenticationService.getRole()
    if(role == 'ROLE_DEVICE_USER' || role == 'ROLE_USER'){
      return false;
    }else {
      return true;
    }
  }


  ngOnDestroy() {
  }
}
