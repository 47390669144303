import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { SuccessErrorDialogService } from '../success-error-dialog/success-error-dialog.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  email = '';
  password = '';
  errorMessage = 'Invalid Credentials';
  message = ''
  invalidLogin = false;
  resetPasswordPage = false
  passwordSent = false

  constructor(
              private router: Router,
              private authenticationService: AuthenticationService,
              private successErrorService: SuccessErrorDialogService
              ) { }

  ngOnInit() {
    if (this.authenticationService.isUserLoggedIn()) {
      this.router.navigate(['dashboard']);
    }
  }

  handleJWTAuthLogin() {
    this.authenticationService.executeJWTAuthenticationService(this.email, this.password)
         .subscribe(
           data => { 
                    if (data['role'] == 'ROLE_DEVICE_USER') {
                      this.router.navigate(['dashboard']);
                      this.invalidLogin = false;
                    } else {
                      this.router.navigate(['dashboard']);
                      this.invalidLogin = false;
                    }
           },
           error => {
             console.log(error);
             this.invalidLogin = true;
           }
         );
  }

  resetPassword() {
      let userDetail = {
        'username': this.email
      }

      this.authenticationService.resetPassword(userDetail).subscribe(res => {
        if (res['status'] == 'OK') {
          this.message = `Reset password link successfully sent to ${this.email}`
          this.passwordSent = true
          setTimeout(() => {
            this.message = ''
            this.passwordSent = false
            this.resetPasswordPage = false
            this.password = ''
          }, 2000);
        }
      },
      err => {
        this.successErrorService.showMessage('error', err)
      })
  }

  openResetPasswordPage() {
    this.invalidLogin = false
    this.resetPasswordPage = true
  }
}
