import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Battery } from 'src/app/service/classes/battery';
import { BatteryDataService } from 'src/app/service/data/form/battery-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {  BATTERY_CHARGING_MODE, EVENTS } from 'src/app/app.constants';
import { ModulePopupService } from 'src/app/service/module-popup.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import * as $ from 'jquery'


@Component({
  selector: 'app-battery-settings',
  templateUrl: './battery-settings.component.html',
  styleUrls: ['./battery-settings.component.css']
})
export class BatterySettingsComponent implements OnInit {

  @Input() batteryDetail: any;
  @Input() dcdId: number;
  @Input() adId: number;
  batteryForm: FormGroup;
  @Output() updatedList = new EventEmitter<any>()
  ChargingModes = [];
  Id = ''
  events: { id: number; name: string; }[];
  @Input() connected

  constructor(private fb: FormBuilder,
    private batterydataservice: BatteryDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogerService: ModulePopupService,
    private successErrorService: SuccessErrorDialogService) {
    this.createForm({});
    this.ChargingModes = BATTERY_CHARGING_MODE;
    this.events = EVENTS
  }

  ngOnInit() {
    this.createForm(this.batteryDetail)
    this.Id = this.batteryDetail['id']
  }

  createForm(obj) {
    this.batteryForm = this.fb.group({
      name: [obj.name || `Battery`, Validators.required],
      state: [obj.state ? true : false, Validators.required],
      capacity: [(obj.capacity >= 1 && obj.capacity <= 100 ) ? obj.capacity : 1, Validators.compose([
        Validators.required, Validators.min(1), Validators.max(100)])],
      chargingMode: [(obj.chargingMode >= 0 && obj.chargingMode <= 3 ) ? obj.chargingMode : 0, Validators.required],
      healthCheckMode: [obj.healthCheckMode || 2, Validators.required], 
      healthCheckTime: [obj.healthCheckTime || 0,
        Validators.required],
      installTime: [obj.installTime ? this.toDateString(new Date(obj.installTime * 1000)) : this.toDateString( new Date()), Validators.required],
      expiryTime: [obj.expiryTime || 0, Validators.required ],
      event: [obj.event ? obj.event : 0]
    });
  }


  getallinputfromdata(dcdId, adsId) {
    return this.batterydataservice._getbatterylist(dcdId, adsId)
      .subscribe(response => {
        this.batteryDetail = response['list'].find(x => x['id'] == this.Id)
        this.createForm(this.batteryDetail)
        this.updatedList.emit(response)
      },
        errors => {
          this.successErrorService.showMessage('error', errors)
        });
  }

  saveBattery(id, batteryData, batteryForm) {
    $('#cover-spin').show()
    this.dialogerService.disableClosing()
    batteryData = { ...batteryData, ...batteryForm.value };
    batteryData['state'] = batteryData['state'] ? 1 : 0;
    batteryData['chargingMode'] = +batteryData['chargingMode'];
    batteryData['healthCheckMode'] = +batteryData['healthCheckMode'];

    let installTime = batteryData['installTime'] ? new Date(batteryData['installTime']).toUTCString() : new Date().toUTCString()
    batteryData['installTime'] = Math.floor(new Date(installTime).valueOf() / 1000)

    return this.batterydataservice.putbatterylist(this.dcdId, this.adId, id, batteryData).subscribe(
      data => {
        $('#cover-spin').hide()
        if(this.connected) {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Sent.')
        } else {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Not Sent.')
        }
        setTimeout(() => {
          this.getallinputfromdata(this.dcdId, this.adId);
          this.dialogerService.enableClosing()
        }, 2000);
      },err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
        this.dialogerService.enableClosing()
      }
    );
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }

}
