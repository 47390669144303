import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../admin.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-configure-new-device',
  templateUrl: './configure-new-device.component.html',
  styleUrls: ['./configure-new-device.component.scss']
})
export class ConfigureNewDeviceComponent implements OnInit {

  deviceName = ''
  signature = '' 
  formData: any
  isQuantDevice = false

  constructor(public dialogRef: MatDialogRef<ConfigureNewDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private successErrorService: SuccessErrorDialogService) { 
      this.formData = this.formBuilder.group({
        deviceName: ['', Validators.required],
        signature: ['', Validators.required],
        noOfDoors: [1],
        noOfReaders: [1] ,
        noOfOutputs: [1]
      })
    }

  ngOnInit() {
    this.formData.patchValue({
      deviceName: this.data['name'] || '',
      signature: this.data['signature']
    })
    if(this.data['signature'].includes('QUANT'))
      this.isQuantDevice = true

  }

  configureNewDevice(formData) {
    $('#cover-spin').show()

    let reqObject = {
      "signature": formData['signature'],
      "name": formData['deviceName']
    }

    if(this.isQuantDevice) {
      reqObject['noOfDoors'] = formData['noOfDoors'],
      reqObject['noOfReaders'] = formData['noOfReaders']
      reqObject['noOfOutputs'] = formData['noOfOutputs']
    }
    
    this.adminService.configureDevice(reqObject).subscribe(res => {
      $('#cover-spin').hide()
      this.adminService.updateListOne()
      this.adminService.updateListTwo()
      this.dialogRef.close()
    }, 
    err => {
      $('#cover-spin').hide()
      this.successErrorService.showMessage('error', err)
    })
  }

}
