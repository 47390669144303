import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { API_URL } from '../app.constants';
import { createHeaders } from '../app.util';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  listUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(private http: HttpClient) { }

  createUser(user: object): Observable<any> {
    return this.http.post(`${API_URL}/users`, user, createHeaders());
  }

  updateUser(id: any, user: object): Observable<any> {
    return this.http.put(`${API_URL}/users/${id}`, user, createHeaders());
  }

  deleteUser(id: any): Observable<any> {
    return this.http.delete(`${API_URL}/users/${id}`, createHeaders());
  }

  getUsers(): Observable<any> {
    return this.http.get(`${API_URL}/users`, createHeaders());
  }

  updateList() {
    this.listUpdated.next(true)
  }

  resetPassword(userDetail: object) {
    let customHeaders = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    
    return this.http.post(`${API_URL}/auth/forgotPassword`, userDetail, customHeaders)
  }

  resetPasswordBySuperAdmin(req) {
    let customHeaders = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${API_URL}/auth/superAdmin/resetPassword`, req, customHeaders)
  }
}
