import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SuperAdminService } from '../super-admin.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss', '../super-admin.component.scss']
})
export class AddUserComponent implements OnInit {

  formData: any
  roles = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_DEVICE_USER']

  constructor(public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private superAdminService: SuperAdminService,
    private formBuilder: FormBuilder,
    private successErrorService: SuccessErrorDialogService,
  ) {
    this.dialogRef.disableClose = true

    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      role: ['', Validators.required]
    })
  }

  ngOnInit() {

  }

  clear() {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      role: ['', Validators.required]
    })
  }

  createUser() {
    let reqBody = { ...this.formData.value }

    this.superAdminService.createUser(reqBody).subscribe(res => {
      if (res) {
        this.successErrorService.showMessage('success', 'User added successfully')
        this.dialogRef.close()
        this.superAdminService.updateList()
      }
    }, err => {
      this.successErrorService.showMessage('error', err)
    })
  }

}
