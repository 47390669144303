import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OutputDataService } from 'src/app/service/data/form/output-data.service';
import { ModulePopupService } from 'src/app/service/module-popup.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import * as $ from 'jquery'
import { OUTPUT_TYPES, EVENTS } from 'src/app/app.constants';

@Component({
  selector: 'app-output-settings',
  templateUrl: './output-settings.component.html',
  styleUrls: ['./output-settings.component.css']
})
export class OutputSettingsComponent implements OnInit {

  @Input() outputDetail: any;
  @Input() dcdId: number;
  @Input() adId: number;
  @Output() updatedList = new EventEmitter<any>()
  outputForm: FormGroup;
  selectedLoad = [];
  dropDownSettings = {};
  Id = ''
  Triggers = [];
  LoadShedding = [];
  outTypes = []
  events: { id: number; name: string; }[];
  @Input() connected

  constructor(private fb: FormBuilder,
    private outputdataservice: OutputDataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogerService: ModulePopupService,
    private successErrorService: SuccessErrorDialogService) {
      this.outTypes = OUTPUT_TYPES
      this.events = EVENTS
  }

  ngOnInit() {
    this.createForm(this.outputDetail)
    this.Id = this.outputDetail['id']
  }

  createForm(obj) {
    this.outputForm = this.fb.group({
      name: [obj.name || `Output`, Validators.required],
      state: [obj.state ? true : false, Validators.required],
      energized: [obj.energized ? true : false, Validators.required],
      actionTime: [(obj.actionTime >= 100 && obj.actionTime <=1000 ) ? obj.actionTime : 100, Validators.compose([
        Validators.required, Validators.min(100), Validators.max(1000)])],
      outType: [obj.outType ? obj.outType : 0],
      outVolt: [obj.outVolt ? obj.outVolt : 0],
      event: [obj.event ? obj.event : 0]
    });
  }


  getallinputfromdata(dcdId, adsId) {
    return this.outputdataservice._getoutputlist(dcdId, adsId)
      .subscribe(response => {
        this.outputDetail = response['list'].find(x => x['id'] == this.Id);
        this.createForm(this.outputDetail)
        this.updatedList.emit(response)
      },
        errors => {
          this.successErrorService.showMessage('error', errors)
        });
  }

  saveOutput(id, outputData, outputForm) {
    $('#cover-spin').show()
    this.dialogerService.disableClosing()
    outputData = { ...outputData, ...outputForm.value };
    outputData['state'] = outputData['state'] ? 1 : 0;
    outputData['energized'] = outputData['energized'] ? 1 : 0;

    return this.outputdataservice.putoutputlist(this.dcdId, this.adId, id, outputData).subscribe(
      data => {
        $('#cover-spin').hide()
        if(this.connected) {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Sent.')
        } else {
          this.successErrorService.showMessage('success', 'Updated successfully! Command Not Sent.')
        }
        setTimeout(() => {
          this.getallinputfromdata(this.dcdId, this.adId);
          this.dialogerService.enableClosing()
        }, 2000);
      },
      err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
        this.dialogerService.enableClosing()
      }
    );
  }
}
