import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../app.constants';
import { createHeaders } from '../app.util';

@Injectable({
  providedIn: 'root'
})
export class FirmwareUploadService {
  constructor(private http: HttpClient) { }

  uploadEsp(file, adId) {
    return this.http.post(`${API_URL}/api/fw/ad/${adId}/esp`, file, createHeaders());
  }

  uploadPsoc(file, adId) {
    return this.http.post(`${API_URL}/api/fw/ad/${adId}/psoc`, file, createHeaders());
  }

}
