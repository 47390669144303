import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { API_URL } from '../app.constants';
import { createHeaders } from '../app.util';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {

  listUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(private http: HttpClient) { }

  createHoliday(holiday: object): Observable<any> {
    return this.http.post(`${API_URL}/api/holiday`, holiday, createHeaders());
  }

  updateHoliday(id: any, holiday: object): Observable<any> {
    return this.http.put(`${API_URL}/api/holiday/${id}`, holiday, createHeaders());
  }

  deleteHoliday(id: any): Observable<any> {
    return this.http.delete(`${API_URL}/api/holiday/${id}`, createHeaders());
  }

  getHolidays(): Observable<any> {
    return this.http.get(`${API_URL}/api/holiday`, createHeaders());
  }

  updateList() {
    this.listUpdated.next(true)
  }
  createFile(type) :Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    return this.http.get(API_URL + `/api/holiday/export/${type}`, requestOptions)
  }
  downloadFile(type,fileName) :Observable<Blob> {
    const headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'blob' as 'json'
    }
    return this.http.get<Blob>(API_URL + `/api/holiday/download/${type}/${fileName}`, requestOptions)
  }
}