import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/app.constants';
import { map } from 'rxjs/operators';
import { InputList } from '../../classes/inputlist';
import { createHeaders } from 'src/app/app.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InputDataService {

  constructor(private http: HttpClient) { }

  getinputalllist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/input`, createHeaders())
    .pipe(
      map((response: Response) => {
        const data = response;
        return data;
      })
      );
  }

  getinputlist(dcdId, adId, id) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/input/${id}`, createHeaders());
  }

  _getinputalllist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/input`, createHeaders());
  }

  putinputlist(dcdId, adId, id, inputlist: InputList) : Observable<any> {
    return this.http.put(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/input/${id}`, inputlist, createHeaders());
  }
}
