import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SuperAdminService } from '../super-admin.service';
import { FormBuilder, Validators } from '@angular/forms';
import { openDialog } from 'src/app/warning-dialog/opendialog';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import { CookiesService } from 'src/app/cookies.service';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss', '../super-admin.component.scss']
})
export class EditUserComponent implements OnInit {
  formData: any
  message: string;
  roles = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_DEVICE_USER', 'ROLE_SUPER_ADMIN']
  deviceUserCreated = false
  disableDeviceUserInList = false
  loginUser = ''

  constructor(public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private superAdminService: SuperAdminService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private successErrorService: SuccessErrorDialogService,
    private cookiesService: CookiesService
  ) {

    this.deviceUserCreated = this.data[1]
    this.data = this.data[0]
    if (this.data['role'] != 'ROLE_DEVICE_USER' && this.deviceUserCreated) {
      this.disableDeviceUserInList = true
    }

    this.dialogRef.disableClose = true

    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      role: ['', Validators.required],
      active: ['', Validators.required]
    })

  }

  ngOnInit() {
    this.formData.patchValue({
      name: this.data['name'],
      email: this.data['email'],
      role: this.data['role'],
      active: this.data['active']
    })

    this.loginUser = this.cookiesService.getCookie('authenticaterUser')
  }


  deleteUser() {
    openDialog(this.dialog, x => {
      if (x) {
        this.superAdminService.deleteUser(this.data['id']).subscribe(res => {
          if (res['status'] == 'OK') {
            this.message = 'User deleted successfully'
            setTimeout(() => {
              this.message = ''
              this.dialogRef.close()
              this.superAdminService.updateList()
            }, 2000);
          }

        },
          err => {
            this.successErrorService.showMessage('error', err)
          })
      }
    })
  }

  onReset() {
    this.formData.patchValue({
      name: this.data['name'],
      email: this.data['email'],
      role: this.data['role'],
      active: this.data['active']
    })
  }

  updateUser(formData) {
    let req = { ...formData.value }
    delete req['email']
    this.superAdminService.updateUser(this.data['id'], req).subscribe(res => {
      if (res['id'] == this.data['id']) {
        this.message = 'User updated successfully'
        setTimeout(() => {
          this.message = ''
          this.superAdminService.updateList()
        }, 2000);
      }

    },
      err => {
        this.successErrorService.showMessage('error', err)
      })
  }

}
