import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_URL, TOKEN, AUTHENTICATED_USER, SELECTED_AD, AD_RECEIVED, DCD_RECEIVED } from '../app.constants';
import { createHeaders } from 'src/app/app.util';
import { CookiesService } from '../cookies.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  sessionTimer: any

  constructor(private http: HttpClient, private cookiesService: CookiesService) {
  }

  executeJWTAuthenticationService(username, password): Observable<any> {

    return this.http.post<any>(`${API_URL}/auth/signIn`, {
      username,
      password
    }).pipe(
      map(
        data => {
          if (data['role'] == 'ROLE_DEVICE_USER') {
            this.cookiesService.setCookie(AUTHENTICATED_USER, data['username'], 15)
            this.cookiesService.setCookie(TOKEN, `Bearer ${data['token']}`, 15)
            this.cookiesService.setCookie('ROLE', data['role'], 15)
            this.sessionTimer = this.startSessionTimer()
            return data
          } else {
            this.cookiesService.setCookie(AUTHENTICATED_USER, data['username'], 15)
            this.cookiesService.setCookie(TOKEN, `Bearer ${data['token']}`, 15)
            this.cookiesService.setCookie('ROLE', data['role'], 15)
            this.sessionTimer = this.startSessionTimer()
            return data;
          }
        }
      )
    );
  }

  executeJWTRegenerateTokenService() {
    if (this.cookiesService.getCookie('REGENERATING_TOKEN') == 'true')
      return

    this.cookiesService.setCookie('REGENERATING_TOKEN', 'true', 15)

    this.http.post<any>(`${API_URL}/auth/regenerateToken`, null, createHeaders()).subscribe(
      data => {
        if (data['role'] == 'ROLE_DEVICE_USER') {
          return data
        } else {
          this.cookiesService.setCookie(AUTHENTICATED_USER, data['username'], 15)
          this.cookiesService.setCookie(TOKEN, `Bearer ${data['token']}`, 15)
          this.cookiesService.setCookie('ROLE', data['role'], 15)
          clearInterval(this.sessionTimer)
          this.sessionTimer = this.startSessionTimer()
          this.cookiesService.setCookie('REGENERATING_TOKEN', '', 15)
          return data;
        }
      }
    );
  }

  startSessionTimer() {
    let timeInMins = 45
    this.cookiesService.setCookie('SESSION_TIME_LEFT', timeInMins.toString(), 15);

    return setInterval(() => {
      timeInMins--
      this.cookiesService.setCookie('SESSION_TIME_LEFT', timeInMins.toString(), 15);
    }, 60000)
  }

  getAuthenticatedUser() {
    return this.cookiesService.getCookie(AUTHENTICATED_USER);
  }

  getAuthenticatedToken() {
    if (this.getAuthenticatedUser()) {
      return this.cookiesService.getCookie(TOKEN);
    }
  }

  isUserLoggedIn() {
    const user = this.cookiesService.getCookie(AUTHENTICATED_USER);
    return user != '' ? true : false;
  }

  isDCDReceived() {
    return this.cookiesService.getCookie(DCD_RECEIVED);
  }

  isADReceived() {
    return this.cookiesService.getCookie(AD_RECEIVED);
  }

  selectedAD() {
    return this.cookiesService.getCookie(SELECTED_AD);
  }

  getRole() {
    return this.cookiesService.getCookie('ROLE');
  }

  clearAllStorage() {
    localStorage.clear()
    clearInterval(this.sessionTimer)
    this.cookiesService.setCookie(AUTHENTICATED_USER, '', -15)
    this.cookiesService.setCookie(TOKEN, '', -15)
    this.cookiesService.setCookie('ROLE', '', -15)
    this.cookiesService.setCookie('SESSION_TIME_LEFT', '', -15)
    this.cookiesService.setCookie('REGENERATING_TOKEN', '', -15)
  }

  resetPassword(userDetail) {
    let customHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(`${API_URL}/auth/forgotPassword`, userDetail, customHeaders)
  }

  logout() {
    return this.http.post<any>(`${API_URL}/auth/logout`, null, createHeaders());
  }
}

