import { Component, Inject, OnInit } from '@angular/core';
import { API_URL, cardSubTypes, mediumTypes } from 'src/app/app.constants';
import * as $ from 'jquery'
import { CardDetailsService } from '../card-details.service';
import { SuccessErrorDialogService } from 'src/app/success-error-dialog/success-error-dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReaderDataService } from 'src/app/service/data/form/reader-data.service';
import { EventSourcePolyfill } from 'ng-event-source';
import { CookiesService } from 'src/app/cookies.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit {

  deviceList: Object[]
  cardDetailsForm: FormGroup
  mediumTypes: Object[]
  subTypes: Object[]
  readerList: Number[]
  cardDetailsEventSource: EventSourcePolyfill
  closeTimer: any;

  constructor(private cardDetailsService: CardDetailsService,
    private successErrorService: SuccessErrorDialogService,
    private readerDataService: ReaderDataService,
    private formBuilder: FormBuilder,
    private cookiesService: CookiesService,
    private dialogRef: MatDialogRef<CardDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.getDevicelist()

      this.mediumTypes = mediumTypes
      this.subTypes = cardSubTypes
      this.cardDetailsForm = this.formBuilder.group({
        id: ['', Validators.required],
        command: [6, Validators.required],
        readerNum: ['', Validators.required],
        mediumType: ['', Validators.required],
        subType: ['', Validators.required]
      })
    }

  ngOnInit() {
  }

  getDevicelist() {
    $('#cover-spin').show()
    this.cardDetailsService.getDeviceList().subscribe(
      (response) => {
        if (response['list'].length > 0) {
          this.deviceList = response['list']
          this.deviceList = this.deviceList.map(device =>  device['accessDeviceVOList'][0])
        }
        $('#cover-spin').hide()
      },
      err => {
        $('#cover-spin').hide()
        this.successErrorService.showMessage('error', err)
      }
    );
  }

  selectDevice(event){
    let deviceId = event['value']

    let device = this.deviceList.find(d => d['id'] == deviceId)
    
    this.readerDataService.getreaderalllist(device['dcdId'], device['id']).subscribe(
      res => {
        this.readerList = res['list']
      },  
      err => {
        this.successErrorService.showMessage('error', err)
      }
    )
  }

  sendCardDetails() {
    let cardDetailsForm = this.cardDetailsForm.value

    this.cardDetailsService.sendCardDetails(cardDetailsForm).subscribe(
      res => {
        this.successErrorService.showMessage('success', 'Details sent. Please flash the card.')
        this.receiveCardDetails()
        $('#spinner').css('display', 'block')
        $('#card-details-container').css('opacity', '0.4')
        this.dialogRef.disableClose = true
        this.closeTimer = setTimeout(() => {
          this.cardDetailsEventSource.close()
          $('#spinner').css('display', 'none')
          $('#card-details-container').css('opacity', '1')
          this.successErrorService.showMessage('error', 'Timed out. No details received.')
          this.dialogRef.close()
        }, 12000);
      },
      err => {
        this.successErrorService.showMessage('error', err)
      }
    )
  }

  receiveCardDetails() {
    
    this.cardDetailsEventSource = new EventSourcePolyfill(API_URL + '/api/sse/card-details-events', {
      heartbeatTimeout: 1800000,
      connectionTimeout: 360000,
      headers: {
        'authorization': this.cookiesService.getCookie('token')
      }
    });

    this.cardDetailsEventSource.onmessage = (event) => {
        let cardDetails = JSON.parse(event['data'])
        let index = this.data[0]
        cardDetails['index'] = index
        cardDetails['type'] = this.data[1]

        this.cardDetailsService.setCardDetails(cardDetails)
        clearTimeout(this.closeTimer)
        $('#spinner').css('display', 'none')
        $('#card-details-container').css('opacity', '1')
        this.dialogRef.close()
        this.cardDetailsEventSource.close()       
    }
}

  closeDialog() {
    this.dialogRef.close()
  }

}
