import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/app.constants';
import { createHeaders } from 'src/app/app.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutputDataService {

  constructor(private http: HttpClient) { }

  getoutputalllist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/output`, createHeaders());
  }

  getoutputlist(dcdId, adId, id) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/output/${id}`, createHeaders());
  }
  
  _getoutputlist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/output`, createHeaders());
  }

  putoutputlist(dcdId, adId, id, outputlist) : Observable<any> {
    return this.http.put(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/output/${id}`, outputlist, createHeaders());
  }
}
