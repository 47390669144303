import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/app.constants';
import { createHeaders } from 'src/app/app.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReaderDataService {

  constructor(private http: HttpClient) { }

  getreaderalllist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/reader`, createHeaders());
  }

  getreaderlist(dcdId, adId, id) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/reader/${id}`, createHeaders());
  }

  _getreaderlist(dcdId, adId) : Observable<any> {
    return this.http.get(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/reader`, createHeaders());
  }

  putreaderlist(dcdId, adId, id, reader) : Observable<any> {
    return this.http.put(`${API_URL}/api/dcd/${dcdId}/ad/${adId}/reader/${id}`, reader, createHeaders());
  }
}
