import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL, TOKEN } from 'src/app/app.constants';
import { CookiesService } from './cookies.service';

export const createHeaders = () => {


  return {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':  getCookie(TOKEN)
    })
  };
  
};

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}