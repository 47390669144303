import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API_URL } from '../app.constants';
import { createHeaders } from '../app.util';

@Injectable({
  providedIn: 'root'
})
export class CardDetailsService {

  cardDetails: BehaviorSubject<Object> = new BehaviorSubject<Object>({})

  constructor(private http: HttpClient) { }

  getDeviceList() {
    return this.http.get(`${API_URL}/api/dcd/includeAds`, createHeaders())
  }

  sendCardDetails(cardDetails: Object) {
    return this.http.post(`${API_URL}/api/accessor/sendDetailsForCard`, cardDetails, createHeaders())
  }

  receiveCardDetails() {
    return this.cardDetails
  }

  setCardDetails(details) {
    this.cardDetails.next(details)
  }
}
