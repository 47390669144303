import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuardService implements CanActivate {  
  role: string;
  constructor(
              private authenticationService: AuthenticationService 
  ) {
    this.role = authenticationService.getRole()
   }

  canActivate( ) {

    if (this.authenticationService.isUserLoggedIn() && this.role == 'ROLE_SUPER_ADMIN' || this.role == 'ROLE_ADMIN') {
      return true
    } else { 
     return false;
  }
  }
}
