import { Component } from '@angular/core';
import { AppConfig } from './app-config';
import { setApiUrl, AUTHENTICATED_USER, DCD_RECEIVED, API_URL } from './app.constants';
import { ConfigService } from './config.service';
import { CookiesService } from './cookies.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'smartdoor';
  appConfig: AppConfig

  constructor(private cookiesService: CookiesService, private configService: ConfigService) {
      this.appConfig = configService.readConfig()
      setApiUrl(this.appConfig['api'])
   }

  getnavlist() {
    return (this.cookiesService.getCookie(DCD_RECEIVED) && this.cookiesService.getCookie(AUTHENTICATED_USER)) ? true : false;
  }
}


