import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authenticated: AuthenticationService) {
  }

  ngOnInit() {

    this.authenticated.logout().subscribe(
      (response) => {
        this.authenticated.clearAllStorage();
      },
      err => {
        this.authenticated.clearAllStorage();
      }
    );
  }

}
