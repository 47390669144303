import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SuccessErrorDialogComponent } from './success-error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SuccessErrorDialogService {

  constructor(private dialog: MatDialog) { }

  showMessage(messageType, message) {
    const dialogRef = this.dialog.open(SuccessErrorDialogComponent, {
      width: '520px',
      height: '80px',
      position: {
        'top': '10px'
      },
      data: {
        message,
        messageType
      }
    });
  }
}
