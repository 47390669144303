import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, FormControl, ReactiveFormsModule} from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import {AppComponent} from './app.component';
import {SigninComponent} from './signin/signin.component';
import {ErrorComponent} from './error/error.component';
import {FooterComponent} from './footer/footer.component';
import {LogoutComponent} from './logout/logout.component';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule} from '@angular/router';
import {RouteGuardService} from './service/route-guard.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatCheckboxModule, MatRadioModule, MatDatepicker, MatDatepickerModule, MatNativeDateModule, MatTableModule, MatGridListModule, MatProgressBarModule, MatMenu, MatMenuModule, MatProgressSpinnerModule
} from '@angular/material';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {InputSettingsComponent} from './input-settings/input-settings.component';
import {OutputSettingsComponent} from './output-settings/output-settings.component';
import {BatterySettingsComponent} from './battery-settings/battery-settings.component';
import {CabinetSettingsComponent} from './cabinet-settings/cabinet-settings.component';
import {DoorSettingsComponent} from './door-settings/door-settings.component';
import {MainComponent} from './main/main.component';
import {MatCardModule} from '@angular/material/card';
import {MatTreeModule} from '@angular/material/tree';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BasicAuthInterceptor} from './service/http/basic-auth-interceptor.service';
import {ErrorInterceptor} from './service/http/error-interceptor.service';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSearchBarComponent } from 'ng-mat-search-bar/src/app/ng-mat-search-bar/mat-search-bar/mat-search-bar.component';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { ModulePopup } from './module-popup/module-popup.component';
import { ConfigureNewDeviceComponent } from './admin/configure-new-device/configure-new-device.component';
import { UpdateDeviceComponent } from './admin/update-device/update-device.component'; 
import { AddHolidayComponent } from './holidays/add-holiday/add-holiday.component';
import { EditHolidayComponent } from './holidays/edit-holiday/edit-holiday.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from 'ngx-mat-datetime-picker';
import { AddUserComponent } from './super-admin/add-user/add-user.component';
import { EditUserComponent } from './super-admin/edit-user/edit-user.component';
import { SuperAdminGuardService } from './service/super-admin-guard.service';
import { AdminGuardService } from './service/admin-guard.service';
import { SuccessErrorDialogComponent } from './success-error-dialog/success-error-dialog.component';
import { ReaderSettingsComponent } from './reader-settings/reader-settings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { ResetPasswordComponent } from './super-admin/reset-password/reset-password.component';
import { ConfigService } from './config.service';
import { APP_INITIALIZER } from '@angular/core';
import { CardDetailsComponent } from './user/card-details/card-details.component';

const appInitializerFn = (configService: ConfigService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ErrorComponent,
    FooterComponent,
    LogoutComponent,
    InputSettingsComponent,
    OutputSettingsComponent,
    BatterySettingsComponent,
    CabinetSettingsComponent,
    DoorSettingsComponent,
    ReaderSettingsComponent,
    MainComponent,
    WarningDialogComponent,
    ModulePopup,
    ConfigureNewDeviceComponent,
    UpdateDeviceComponent,
    AddHolidayComponent,
    EditHolidayComponent,
    AddUserComponent,
    EditUserComponent,
    SuccessErrorDialogComponent,
    DashboardComponent,
    ResetPasswordComponent,
    CardDetailsComponent
  ],
  imports: [
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    BrowserModule,
    FormsModule,
    AngularMultiSelectModule,
    AngularDualListBoxModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatTreeModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatTableModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    InfiniteScrollModule,
    MatSnackBarModule,
    MatListModule,
    NgMatSearchBarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatGridListModule,
    MatProgressBarModule,
    MatMenuModule,
    NgxMatDrpModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    WarningDialogComponent,
    ModulePopup,
    ConfigureNewDeviceComponent,
    UpdateDeviceComponent,
    AddHolidayComponent,
    EditHolidayComponent,
    AddUserComponent,
    EditUserComponent,
    SuccessErrorDialogComponent,
    ResetPasswordComponent,
    CardDetailsComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    RouteGuardService,
    SuperAdminGuardService,
    AdminGuardService,
    MainComponent,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
